const state = {
  showModalEditCaasTemplate: false,
  showG2ReviewModal: false,
  showInviteUserModal: false,
  showNewsModal: false,
  showRenameModal: false,
  showMessageModal: false,
  showProjectModal: false,
  showDocumentProjectModal: false,
  showCreateFolderModal: false,
  showSelectFolderModal: false,
  showCreateTranslationModal: false,
  showCreateServiceModal: false,
  showDeleteServiceModal: false,
  showAddTimeOffModal: false,
  showRemoveTimeOffModal: false,
  showClientServicesModal: false,
  showDeleteLanguagePairModal: false,
  showRemoveAccountModal: false,
  showServiceClientsModal: false,
  showMfaModal: false,
  showVerifyMfaModal: false,
  showCooperationAgreementModal: false,
  showExceededVoucherModal: false,
  showMultilingualProjectModal: false,
  showCaasIntroModal: false,
  showUpgradeSubscriptionModal: false,
  showRenameCaasDocumentModal: false,
  showDeleteCaasDocumentModal: false,
  showCaasDownloadModal: false,
  showImportTemplateModal: false,
  showinstructionsModal: false,
  showAreaOfWorkModal: false,
  showCaasAnimationModal: false,
  showAiIntroModal: false,
  showCreateTermModal: false
}

const mutations = {
  toggleModalEditCaasTemplate(state) {
    state.showModalEditCaasTemplate = !state.showModalEditCaasTemplate
  },
  toggleG2ReviewModal(state) {
    state.showG2ReviewModal = !state.showG2ReviewModal
  },
  toggleInviteUserModal(state) {
    state.showInviteUserModal = !state.showInviteUserModal
  },
  toggleProjectModal(state) {
    state.showProjectModal = !state.showProjectModal
  },
  toggleUpgradeSubscriptionModal(state) {
    state.showUpgradeSubscriptionModal = !state.showUpgradeSubscriptionModal
  },
  toggleDocumentProjectModal(state) {
    state.showDocumentProjectModal = !state.showDocumentProjectModal
  },
  toggleCaasIntroModal(state) {
    state.showCaasIntroModal = !state.showCaasIntroModal
  },
  toggleExceededVoucherModal(state) {
    state.showExceededVoucherModal = !state.showExceededVoucherModal
  },
  toggleMultilingualProjectModal(state) {
    state.showMultilingualProjectModal = !state.showMultilingualProjectModal
  },
  toggleSelectFolderModal(state) {
    state.showSelectFolderModal = !state.showSelectFolderModal
  },
  toggleNewsModal(state) {
    state.showNewsModal = !state.showNewsModal
  },
  toggleMessageModal(state) {
    state.showMessageModal = !state.showMessageModal
  },
  toggleRenameModal(state, payload) {
    state.showRenameModal = payload
  },
  toggleCreateFolderModal(state) {
    state.showCreateFolderModal = !state.showCreateFolderModal
  },
  toggleTranslationModal(state) {
    state.showCreateTranslationModal = !state.showCreateTranslationModal
  },
  toggleCreateServiceModal(state) {
    state.showCreateServiceModal = !state.showCreateServiceModal
  },
  toggleDeleteServiceModal(state) {
    state.showDeleteServiceModal = !state.showDeleteServiceModal
  },
  toggleAddTimeOffModal(state) {
    state.showAddTimeOffModal = !state.showAddTimeOffModal
  },
  toggleRemoveTimeOffModal(state) {
    state.showRemoveTimeOffModal = !state.showRemoveTimeOffModal
  },
  toggleClientServicesModal(state) {
    state.showClientServicesModal = !state.showClientServicesModal
  },
  toggleDeleteLanguagePairModal(state) {
    state.showDeleteLanguagePairModal = !state.showDeleteLanguagePairModal
  },
  toggleRemoveAccountModal(state) {
    state.showRemoveAccountModal = !state.showRemoveAccountModal
  },
  toggleServiceClientsModal(state) {
    state.showServiceClientsModal = !state.showServiceClientsModal
  },
  toggleMfaModal(state) {
    state.showMfaModal = !state.showMfaModal
  },
  setVerifyMfaModal(state, verify) {
    state.showVerifyMfaModal = verify
  },
  setCooperationAgreementModal(state, verify) {
    state.showCooperationAgreementModal = verify
  },
  toggleRenameCaasDocumentModal(state) {
    state.showRenameCaasDocumentModal = !state.showRenameCaasDocumentModal
  },
  toggleDeleteCaasDocumentModal(state) {
    state.showDeleteCaasDocumentModal = !state.showDeleteCaasDocumentModal
  },
  toggleImportTemplateModal(state) {
    state.showImportTemplateModal = !state.showImportTemplateModal
  },
  toggleInstructionsModal(state) {
    state.showinstructionsModal = !state.showinstructionsModal
  },
  toggleAreaOfWorkModal(state) {
    state.showAreaOfWorkModal = !state.showAreaOfWorkModal
  },
  toggleCaasAnimationModal(state) {
    state.showCaasAnimationModal = !state.showCaasAnimationModal
  },
  toggleAiIntroModal(state) {
    state.showAiIntroModal = !state.showAiIntroModal
  },
  toggleCreateTermModal(state) {
    state.showCreateTermModal = !state.showCreateTermModal
  }
}

const actions = {
  setMessageModal({ commit }) {
    commit('toggleMessageModal')
    commit('navigation/toggleSidebar', false, { root: true })
  },
  setNewsModal({ commit }) {
    commit('toggleNewsModal')
    commit('navigation/toggleSidebar', false, { root: true })
  },
  setFolderModal({ commit }) {
    commit('toggleCreateFolderModal')
    commit('navigation/toggleSidebar', false, { root: true })
  },
  triggerMfaVerificationModal({ dispatch, commit }) {
    commit('setVerifyMfaModal', true)
    dispatch('loading/stopLoader', null, { root: true })
  },
  triggerCooperationAgreementModal({ dispatch, commit }) {
    commit('setCooperationAgreementModal', true)
    dispatch('loading/stopLoader', null, { root: true })
  },
  setUpgradeSubModal({ commit }, data) {
    commit('subscriptions/setSelectedSub', data, { root: true })
    commit('toggleUpgradeSubscriptionModal', true)
  }
}

export const modals = {
  namespaced: true,
  state,
  actions,
  mutations
}
