import ApiService from '@/api/new/services/api.service'
import i18n from '@/i18n'
const state = {
  usersInvited: [],
  invitationDetails: {
    isAccepted: false,
    isDeclined: false
  },
  user: {
    email: '',
    password: '',
    password_confirmation: '',
    consents: {
      terms: false,
      ai: false,
      marketing: false
    }
  }
}

const mutations = {
  setUsersInvites(state, invites) {
    state.usersInvited = invites
  },
  setInvitationDetails(state, details) {
    state.invitationDetails = {
      ...state.invitationDetails,
      ...details
    }
  },
  setInvitationStatus(state, isAccepted) {
    state.invitationDetails[`${isAccepted ? 'isAccepted' : 'isDeclined'}`] =
      true
  },
  setUser(state, user) {
    state.user = { ...user }
  },
  clearUserInvites(state) {
    state.usersInvited = []
  }
}

const actions = {
  async getDetails({ commit }, { teamName, token }) {
    const endpoint = `teams/${teamName}/invitations/${token}`
    await ApiService.publicGet(endpoint)
      .then((res) => {
        commit('setInvitationDetails', res.data.data)
        if (teamName) {
          commit(
            'workspace/setCurrentAccount',
            { team_identifier: teamName },
            { root: true }
          )
        }
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async acceptInvitation({ commit, rootGetters }, { id, requestData }) {
    const data = {
      data: {
        type: 'invitation',
        attributes: { ...requestData }
      }
    }
    await ApiService.publicPost(
      `teams/${rootGetters['workspace/currentAccountId']}/invitations/${id}/accept`,
      data
    )
      .then(() => {
        commit('setInvitationStatus', true)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async declineInvitation({ commit, rootGetters }, id) {
    await ApiService.publicPost(
      `teams/${rootGetters['workspace/currentAccountId']}/invitations/${id}/decline`
    )
      .then(() => {
        commit('setInvitationStatus', false)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async sendInvitation({ dispatch, rootGetters }, { email }) {
    const data = {
      data: {
        type: 'invitation',
        attributes: {
          email
        }
      }
    }
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/invitations`,
      data
    )
      .then(async () => {
        dispatch(
          'toast/success',
          {
            message: i18n.t('customer.invitation.team.send_success', { email })
          },
          { root: true }
        )
        await dispatch('getUsersInvitations')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getStateUsersInvitations({ dispatch, state }) {
    if (state.usersInvited.length === 0) await dispatch('getUsersInvitations')
  },
  async getUsersInvitations({ commit, rootGetters }) {
    await ApiService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/invitations`
    )
      .then((res) => {
        const invites = res.data.data
        commit('setUsersInvites', invites)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async cancelUserInvite({ dispatch, rootGetters }, { invitationId }) {
    await ApiService.delete(
      `teams/${rootGetters['workspace/currentAccountId']}/invitations/${invitationId}`
    )
      .then(() => {
        dispatch(
          'toast/success',
          { message: i18n.t('customer.toast.success.invitation_cancelled') },
          { root: true }
        )
        dispatch('getUsersInvitations')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async sendSupplierInvitations({ dispatch }, file) {
    const data = new FormData()

    data.append(`data[attributes][file]`, file[0])

    data.append('data[type]', 'suppliers')
    await ApiService.post(`suppliers/onboarding`, data)
      .then(() => {
        dispatch(
          'toast/success',
          {
            message: i18n.t('supplier.toast.success.invitations_sent')
          },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  }
}

const getters = {}

export const invitations = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
