const state = {
  baseRoute: '',
  showNav: true,
  showSidebar: false,
  routes: {
    workspace: [
      { title: 'customer.nav.overview.btn', route: 'clientOverview' },
      { title: 'customer.sub_nav.folders.btn', route: 'clientFolders' },
      { title: 'customer.sub_nav.projects.btn', route: 'clientProjects' }
    ],
    billing: [
      // { title: 'customer.sub_nav.billing.btn', route: 'clientPaymentBilling' },
      { title: 'customer.sub_nav.wallet.btn', route: 'clientPaymentWallet' },
      { title: 'customer.sub_nav.balance.btn', route: 'clientPaymentBalance' },
      { title: 'shared.sub_nav.history.btn', route: 'clientPaymentHistory' },
      { title: 'customer.sub_nav.price_limit.btn', route: 'clientPriceLimit' }
    ],
    team: [],
    languageAssets: [
      { title: 'customer.sub_nav.tm.btn', route: 'translationMemories' },
      { title: 'customer.sub_nav.tb.btn', route: 'termBanks' }
    ],
    account: [
      {
        title: 'customer.sub_nav.account_info.btn',
        route: 'clientAccountInfo'
      },
      { title: 'shared.sub_nav.profile.btn', route: 'clientProfile' },
      { title: '2FA', route: 'customerMfa' },
      { title: 'SSO', route: 'customerSso' },
      { title: 'customer.sub_nav.privacy.btn', route: 'clientPrivacyConsent' },
      { title: 'GDPR', route: 'customerGDPR' },
      { title: 'customer.sub_nav.reports.btn', route: 'clientReports' }
    ],
    supplierPayment: [
      { title: 'supplier.sub_nav.methods.btn', route: 'supplierSettings' },
      { title: 'shared.sub_nav.history.btn', route: 'supplierPaymentHistory' }
    ],
    supplierAccount: [
      { title: 'shared.sub_nav.profile.btn', route: 'supplierProfile' },
      { title: 'supplier.sub_nav.services.btn', route: 'supplierServices' },
      { title: 'GDPR', route: 'supplierGDPR' },
      { title: '2FA', route: 'supplierMFA' },
      {
        title: 'supplier.sub_nav.cooperation.btn',
        route: 'supplierCooperationAgreement'
      }
    ],
    caas: [
      {
        title: 'customer.sub_nav.caas.plain_document',
        route: 'contentGenerationV2'
      },
      { title: 'customer.sub_nav.caas.documents', route: 'caasDocuments' },
      { title: 'customer.sub_nav.caas.templates', route: 'caasTemplates' }
    ]
  }
}

const mutations = {
  showSubNavigation(state, bool) {
    state.showNav = bool
  },
  toggleSidebar(state, bool) {
    state.showSidebar = bool
  },
  toggleNav(state) {
    state.showNav = !state.showNav
  },
  setBaseRoute(state, name) {
    state.baseRoute = name
  },
  addTeamRoute(state, route) {
    if (!state.routes.team.some((r) => r.route === route.route)) {
      state.routes.team.splice(0, 0, route)
    }
  },
  addBillingRoute(state, route) {
    if (!state.routes.billing.some((r) => r.route === route.route)) {
      state.routes.billing.splice(2, 0, route)
    }
  },
  resetPaymentRoute(state, route) {
    if (state.routes.billing.some((r) => r.route === route.route)) {
      state.routes.billing.splice(2, 1)
    }
  },
  addPricingRoute(state, route) {
    if (!state.routes.billing.some((r) => r.route === route.route)) {
      state.routes.billing.splice(3, 0, route)
    }
  }
}

const actions = {
  async addAccessRoutes({ commit, rootGetters }) {
    await commit('resetPaymentRoute', {
      title: 'customer.sub_nav.usage.btn',
      route: 'clientUsage'
    })
    if (rootGetters['teams/userHasAccess']) {
      commit('addTeamRoute', {
        title: 'customer.sub_nav.roles_permission.btn',
        route: 'clientRolesPermissions'
      })
      commit('addTeamRoute', {
        title: 'customer.sub_nav.account_structor.btn',
        route: 'clientAccountStructure'
      })
      commit('addTeamRoute', {
        title: 'customer.sub_nav.users_permission.btn',
        route: 'clientUserPermissions'
      })
    }
    if (rootGetters['account/freemiumUsage']) {
      commit('addBillingRoute', {
        title: 'customer.sub_nav.usage.btn',
        route: 'clientUsage'
      })
    }
    if (rootGetters['account/isLaasAccount']) {
      commit('addBillingRoute', {
        title: 'customer.sub_nav.revision_price.btn',
        route: 'clientReviewPriceList'
      })
      commit('addBillingRoute', {
        title: 'customer.sub_nav.translation_price.btn',
        route: 'clientTranslationPrice'
      })
    }
  }
}

const getters = {
  showSubnav(state) {
    if (
      [
        'clientTranslators',
        'news',
        'clientAutomationFlows',
        'clientAutomationFlow',
        'clientLibraries',
        'clientMarketplace',
        'clientZapierConnect',
        'clientRevisionEditor',
        'clientProgressOnboarding',
        'customerAssets',
        'customerChat',
        'contentGenerationV2',
        'clientPillars',
        'download'
      ].includes(state.baseRoute)
    )
      return false
    return true
  }
}

export const navigation = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
